export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=2.0"},{"hid":"title","property":"title","content":"РУСДРАМ"},{"hid":"og:title","property":"og:title","content":"РУСДРАМ"},{"hid":"description","name":"description","content":"Государственный русский театр драмы им. Ф. А. Искандера"},{"hid":"og:description","property":"og:description","content":"Государственный русский театр драмы им. Ф. А. Искандера"},{"hid":"url","name":"url","content":"https://rusdram.org"},{"hid":"og:url","property":"og:url","content":"https://rusdram.org"},{"hid":"image","name":"image","content":"https://rusdram.org/rusdram.jpg"},{"name":"og:image","property":"og:image","content":"https://rusdram.org/rusdram.jpg"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[{"async":true,"defer":true,"crossorigin":"anonymous","src":"/iframeResizer.js"},{"async":true,"defer":true,"crossorigin":"anonymous","src":"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.min.js"},{"async":true,"defer":true,"crossorigin":"anonymous","src":"/svg4everybody.js"},{"async":true,"defer":true,"crossorigin":"anonymous","src":"https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v12.0&appId=463191845423162&autoLogAppEvents=1","nonce":"xcPMLxEx"}],"noscript":[],"title":"РУСДРАМ"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"