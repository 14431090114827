import plugin_vue3_A0OWXRrUgq from "/opt/rusdram/packages/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/rusdram/packages/client/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/opt/rusdram/packages/client/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_Pg0DINazwm from "/opt/rusdram/packages/client/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import prefetch_client_3cwHvxIDEX from "/opt/rusdram/packages/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import openreplay_client_x9gNryAGwo from "/opt/rusdram/packages/client/plugins/openreplay.client.ts";
import router_scrollBehavior_6se0L5aCQz from "/opt/rusdram/packages/client/plugins/router.scrollBehavior.js";
import sentry_client_shVUlIjFLk from "/opt/rusdram/packages/client/plugins/sentry.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_Pg0DINazwm,
  prefetch_client_3cwHvxIDEX,
  openreplay_client_x9gNryAGwo,
  router_scrollBehavior_6se0L5aCQz,
  sentry_client_shVUlIjFLk
]