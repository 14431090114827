import { default as FacebookLoginx8sNqmn1iiMeta } from "/opt/rusdram/packages/client/pages/FacebookLogin.vue?macro=true";
import { default as indexaqoWyJmf0qMeta } from "/opt/rusdram/packages/client/pages/buy/[perform]/[poster]/index.vue?macro=true";
import { default as _91id_93srRtTUJmCWMeta } from "/opt/rusdram/packages/client/pages/compilation/[id].vue?macro=true";
import { default as contactsaW8UJ3NheYMeta } from "/opt/rusdram/packages/client/pages/contacts.vue?macro=true";
import { default as _91slug_93nSmwbhhAZhMeta } from "/opt/rusdram/packages/client/pages/documents/[slug].vue?macro=true";
import { default as indexTZ8nFFkeNfMeta } from "/opt/rusdram/packages/client/pages/documents/index.vue?macro=true";
import { default as indexNZN3xu2SwMMeta } from "/opt/rusdram/packages/client/pages/index.vue?macro=true";
import { default as login0jYUUaolLWMeta } from "/opt/rusdram/packages/client/pages/login.vue?macro=true";
import { default as index4FAeWAvKbMMeta } from "/opt/rusdram/packages/client/pages/media/index.vue?macro=true";
import { default as _91id_93HyEbyNi8ijMeta } from "/opt/rusdram/packages/client/pages/media/photos/[id].vue?macro=true";
import { default as indexmeR3SRcSbqMeta } from "/opt/rusdram/packages/client/pages/media/photos/index.vue?macro=true";
import { default as press2F58Xjn9UlMeta } from "/opt/rusdram/packages/client/pages/media/press.vue?macro=true";
import { default as _91id_93LLmsZRaPsPMeta } from "/opt/rusdram/packages/client/pages/media/videos/[id].vue?macro=true";
import { default as indexSSnc7kkXmeMeta } from "/opt/rusdram/packages/client/pages/media/videos/index.vue?macro=true";
import { default as _91id_93HjJU38PA4nMeta } from "/opt/rusdram/packages/client/pages/news/[id].vue?macro=true";
import { default as indexYLw0xvZSCAMeta } from "/opt/rusdram/packages/client/pages/news/index.vue?macro=true";
import { default as indexy1BFaDr3teMeta } from "/opt/rusdram/packages/client/pages/posters/index.vue?macro=true";
import { default as recovery_45accountfzuMcJgZa8Meta } from "/opt/rusdram/packages/client/pages/recovery-account.vue?macro=true";
import { default as _91id_9376IIFJdtycMeta } from "/opt/rusdram/packages/client/pages/recovery/[id].vue?macro=true";
import { default as _91id_93mRzlL37ul6Meta } from "/opt/rusdram/packages/client/pages/theater/halls/[id].vue?macro=true";
import { default as indexiu4kJi4jTFMeta } from "/opt/rusdram/packages/client/pages/theater/halls/index.vue?macro=true";
import { default as index0C9E8VzwB1Meta } from "/opt/rusdram/packages/client/pages/theater/index.vue?macro=true";
import { default as _91id_93cTGohY7gpQMeta } from "/opt/rusdram/packages/client/pages/theater/performs/[id].vue?macro=true";
import { default as indexlGesEiLkaJMeta } from "/opt/rusdram/packages/client/pages/theater/performs/index.vue?macro=true";
import { default as _91id_93ibAzmnvOd2Meta } from "/opt/rusdram/packages/client/pages/theater/staff/[id].vue?macro=true";
import { default as indexTHGe60ZSH8Meta } from "/opt/rusdram/packages/client/pages/theater/staff/index.vue?macro=true";
import { default as _91id_93LM2byE1I4pMeta } from "/opt/rusdram/packages/client/pages/theater/troupe/[id].vue?macro=true";
import { default as indexXX0I4kDD8NMeta } from "/opt/rusdram/packages/client/pages/theater/troupe/index.vue?macro=true";
import { default as _91id_93oZUCnEDk3zMeta } from "/opt/rusdram/packages/client/pages/theater/voutings/[id].vue?macro=true";
import { default as indexLMDOWfhKcvMeta } from "/opt/rusdram/packages/client/pages/theater/voutings/index.vue?macro=true";
import { default as _91id_93IoMRyNCbm4Meta } from "/opt/rusdram/packages/client/pages/tickets/[id].vue?macro=true";
import { default as facebooktVQkAs2rnlMeta } from "/opt/rusdram/packages/client/pages/user/facebook.vue?macro=true";
import { default as indexXQ15KdZTzhMeta } from "/opt/rusdram/packages/client/pages/user/index.vue?macro=true";
import { default as _91id_93pNkHQyrs60Meta } from "/opt/rusdram/packages/client/pages/user/orders/[id].vue?macro=true";
import { default as _91id_93k0uCpSSVONMeta } from "/opt/rusdram/packages/client/pages/verify/[id].vue?macro=true";
export default [
  {
    name: FacebookLoginx8sNqmn1iiMeta?.name ?? "FacebookLogin",
    path: FacebookLoginx8sNqmn1iiMeta?.path ?? "/FacebookLogin",
    children: [],
    meta: FacebookLoginx8sNqmn1iiMeta,
    alias: FacebookLoginx8sNqmn1iiMeta?.alias || [],
    redirect: FacebookLoginx8sNqmn1iiMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/FacebookLogin.vue").then(m => m.default || m)
  },
  {
    name: indexaqoWyJmf0qMeta?.name ?? "buy-perform-poster",
    path: indexaqoWyJmf0qMeta?.path ?? "/buy/:perform/:poster",
    children: [],
    meta: indexaqoWyJmf0qMeta,
    alias: indexaqoWyJmf0qMeta?.alias || [],
    redirect: indexaqoWyJmf0qMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/buy/[perform]/[poster]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93srRtTUJmCWMeta?.name ?? "compilation-id",
    path: _91id_93srRtTUJmCWMeta?.path ?? "/compilation/:id",
    children: [],
    meta: _91id_93srRtTUJmCWMeta,
    alias: _91id_93srRtTUJmCWMeta?.alias || [],
    redirect: _91id_93srRtTUJmCWMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/compilation/[id].vue").then(m => m.default || m)
  },
  {
    name: contactsaW8UJ3NheYMeta?.name ?? "contacts",
    path: contactsaW8UJ3NheYMeta?.path ?? "/contacts",
    children: [],
    meta: contactsaW8UJ3NheYMeta,
    alias: contactsaW8UJ3NheYMeta?.alias || [],
    redirect: contactsaW8UJ3NheYMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nSmwbhhAZhMeta?.name ?? "documents-slug",
    path: _91slug_93nSmwbhhAZhMeta?.path ?? "/documents/:slug",
    children: [],
    meta: _91slug_93nSmwbhhAZhMeta,
    alias: _91slug_93nSmwbhhAZhMeta?.alias || [],
    redirect: _91slug_93nSmwbhhAZhMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/documents/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTZ8nFFkeNfMeta?.name ?? "documents",
    path: indexTZ8nFFkeNfMeta?.path ?? "/documents",
    children: [],
    meta: indexTZ8nFFkeNfMeta,
    alias: indexTZ8nFFkeNfMeta?.alias || [],
    redirect: indexTZ8nFFkeNfMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: indexNZN3xu2SwMMeta?.name ?? "index",
    path: indexNZN3xu2SwMMeta?.path ?? "/",
    children: [],
    meta: indexNZN3xu2SwMMeta,
    alias: indexNZN3xu2SwMMeta?.alias || [],
    redirect: indexNZN3xu2SwMMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login0jYUUaolLWMeta?.name ?? "login",
    path: login0jYUUaolLWMeta?.path ?? "/login",
    children: [],
    meta: login0jYUUaolLWMeta,
    alias: login0jYUUaolLWMeta?.alias || [],
    redirect: login0jYUUaolLWMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: index4FAeWAvKbMMeta?.name ?? "media",
    path: index4FAeWAvKbMMeta?.path ?? "/media",
    children: [],
    meta: index4FAeWAvKbMMeta,
    alias: index4FAeWAvKbMMeta?.alias || [],
    redirect: index4FAeWAvKbMMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HyEbyNi8ijMeta?.name ?? "media-photos-id",
    path: _91id_93HyEbyNi8ijMeta?.path ?? "/media/photos/:id",
    children: [],
    meta: _91id_93HyEbyNi8ijMeta,
    alias: _91id_93HyEbyNi8ijMeta?.alias || [],
    redirect: _91id_93HyEbyNi8ijMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/media/photos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmeR3SRcSbqMeta?.name ?? "media-photos",
    path: indexmeR3SRcSbqMeta?.path ?? "/media/photos",
    children: [],
    meta: indexmeR3SRcSbqMeta,
    alias: indexmeR3SRcSbqMeta?.alias || [],
    redirect: indexmeR3SRcSbqMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/media/photos/index.vue").then(m => m.default || m)
  },
  {
    name: press2F58Xjn9UlMeta?.name ?? "media-press",
    path: press2F58Xjn9UlMeta?.path ?? "/media/press",
    children: [],
    meta: press2F58Xjn9UlMeta,
    alias: press2F58Xjn9UlMeta?.alias || [],
    redirect: press2F58Xjn9UlMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/media/press.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LLmsZRaPsPMeta?.name ?? "media-videos-id",
    path: _91id_93LLmsZRaPsPMeta?.path ?? "/media/videos/:id",
    children: [],
    meta: _91id_93LLmsZRaPsPMeta,
    alias: _91id_93LLmsZRaPsPMeta?.alias || [],
    redirect: _91id_93LLmsZRaPsPMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/media/videos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSSnc7kkXmeMeta?.name ?? "media-videos",
    path: indexSSnc7kkXmeMeta?.path ?? "/media/videos",
    children: [],
    meta: indexSSnc7kkXmeMeta,
    alias: indexSSnc7kkXmeMeta?.alias || [],
    redirect: indexSSnc7kkXmeMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/media/videos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HjJU38PA4nMeta?.name ?? "news-id",
    path: _91id_93HjJU38PA4nMeta?.path ?? "/news/:id",
    children: [],
    meta: _91id_93HjJU38PA4nMeta,
    alias: _91id_93HjJU38PA4nMeta?.alias || [],
    redirect: _91id_93HjJU38PA4nMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYLw0xvZSCAMeta?.name ?? "news",
    path: indexYLw0xvZSCAMeta?.path ?? "/news",
    children: [],
    meta: indexYLw0xvZSCAMeta,
    alias: indexYLw0xvZSCAMeta?.alias || [],
    redirect: indexYLw0xvZSCAMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexy1BFaDr3teMeta?.name ?? "posters",
    path: indexy1BFaDr3teMeta?.path ?? "/posters",
    children: [],
    meta: indexy1BFaDr3teMeta,
    alias: indexy1BFaDr3teMeta?.alias || [],
    redirect: indexy1BFaDr3teMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/posters/index.vue").then(m => m.default || m)
  },
  {
    name: recovery_45accountfzuMcJgZa8Meta?.name ?? "recovery-account",
    path: recovery_45accountfzuMcJgZa8Meta?.path ?? "/recovery-account",
    children: [],
    meta: recovery_45accountfzuMcJgZa8Meta,
    alias: recovery_45accountfzuMcJgZa8Meta?.alias || [],
    redirect: recovery_45accountfzuMcJgZa8Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/recovery-account.vue").then(m => m.default || m)
  },
  {
    name: _91id_9376IIFJdtycMeta?.name ?? "recovery-id",
    path: _91id_9376IIFJdtycMeta?.path ?? "/recovery/:id",
    children: [],
    meta: _91id_9376IIFJdtycMeta,
    alias: _91id_9376IIFJdtycMeta?.alias || [],
    redirect: _91id_9376IIFJdtycMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/recovery/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93mRzlL37ul6Meta?.name ?? "theater-halls-id",
    path: _91id_93mRzlL37ul6Meta?.path ?? "/theater/halls/:id",
    children: [],
    meta: _91id_93mRzlL37ul6Meta,
    alias: _91id_93mRzlL37ul6Meta?.alias || [],
    redirect: _91id_93mRzlL37ul6Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/halls/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiu4kJi4jTFMeta?.name ?? "theater-halls",
    path: indexiu4kJi4jTFMeta?.path ?? "/theater/halls",
    children: [],
    meta: indexiu4kJi4jTFMeta,
    alias: indexiu4kJi4jTFMeta?.alias || [],
    redirect: indexiu4kJi4jTFMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/halls/index.vue").then(m => m.default || m)
  },
  {
    name: index0C9E8VzwB1Meta?.name ?? "theater",
    path: index0C9E8VzwB1Meta?.path ?? "/theater",
    children: [],
    meta: index0C9E8VzwB1Meta,
    alias: index0C9E8VzwB1Meta?.alias || [],
    redirect: index0C9E8VzwB1Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cTGohY7gpQMeta?.name ?? "theater-performs-id",
    path: _91id_93cTGohY7gpQMeta?.path ?? "/theater/performs/:id",
    children: [],
    meta: _91id_93cTGohY7gpQMeta,
    alias: _91id_93cTGohY7gpQMeta?.alias || [],
    redirect: _91id_93cTGohY7gpQMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/performs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlGesEiLkaJMeta?.name ?? "theater-performs",
    path: indexlGesEiLkaJMeta?.path ?? "/theater/performs",
    children: [],
    meta: indexlGesEiLkaJMeta,
    alias: indexlGesEiLkaJMeta?.alias || [],
    redirect: indexlGesEiLkaJMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/performs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ibAzmnvOd2Meta?.name ?? "theater-staff-id",
    path: _91id_93ibAzmnvOd2Meta?.path ?? "/theater/staff/:id",
    children: [],
    meta: _91id_93ibAzmnvOd2Meta,
    alias: _91id_93ibAzmnvOd2Meta?.alias || [],
    redirect: _91id_93ibAzmnvOd2Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/staff/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTHGe60ZSH8Meta?.name ?? "theater-staff",
    path: indexTHGe60ZSH8Meta?.path ?? "/theater/staff",
    children: [],
    meta: indexTHGe60ZSH8Meta,
    alias: indexTHGe60ZSH8Meta?.alias || [],
    redirect: indexTHGe60ZSH8Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/staff/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LM2byE1I4pMeta?.name ?? "theater-troupe-id",
    path: _91id_93LM2byE1I4pMeta?.path ?? "/theater/troupe/:id",
    children: [],
    meta: _91id_93LM2byE1I4pMeta,
    alias: _91id_93LM2byE1I4pMeta?.alias || [],
    redirect: _91id_93LM2byE1I4pMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/troupe/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXX0I4kDD8NMeta?.name ?? "theater-troupe",
    path: indexXX0I4kDD8NMeta?.path ?? "/theater/troupe",
    children: [],
    meta: indexXX0I4kDD8NMeta,
    alias: indexXX0I4kDD8NMeta?.alias || [],
    redirect: indexXX0I4kDD8NMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/troupe/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oZUCnEDk3zMeta?.name ?? "theater-voutings-id",
    path: _91id_93oZUCnEDk3zMeta?.path ?? "/theater/voutings/:id",
    children: [],
    meta: _91id_93oZUCnEDk3zMeta,
    alias: _91id_93oZUCnEDk3zMeta?.alias || [],
    redirect: _91id_93oZUCnEDk3zMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/voutings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLMDOWfhKcvMeta?.name ?? "theater-voutings",
    path: indexLMDOWfhKcvMeta?.path ?? "/theater/voutings",
    children: [],
    meta: indexLMDOWfhKcvMeta,
    alias: indexLMDOWfhKcvMeta?.alias || [],
    redirect: indexLMDOWfhKcvMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/theater/voutings/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IoMRyNCbm4Meta?.name ?? "tickets-id",
    path: _91id_93IoMRyNCbm4Meta?.path ?? "/tickets/:id",
    children: [],
    meta: _91id_93IoMRyNCbm4Meta,
    alias: _91id_93IoMRyNCbm4Meta?.alias || [],
    redirect: _91id_93IoMRyNCbm4Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: facebooktVQkAs2rnlMeta?.name ?? "user-facebook",
    path: facebooktVQkAs2rnlMeta?.path ?? "/user/facebook",
    children: [],
    meta: facebooktVQkAs2rnlMeta,
    alias: facebooktVQkAs2rnlMeta?.alias || [],
    redirect: facebooktVQkAs2rnlMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/user/facebook.vue").then(m => m.default || m)
  },
  {
    name: indexXQ15KdZTzhMeta?.name ?? "user",
    path: indexXQ15KdZTzhMeta?.path ?? "/user",
    children: [],
    meta: indexXQ15KdZTzhMeta,
    alias: indexXQ15KdZTzhMeta?.alias || [],
    redirect: indexXQ15KdZTzhMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pNkHQyrs60Meta?.name ?? "user-orders-id",
    path: _91id_93pNkHQyrs60Meta?.path ?? "/user/orders/:id",
    children: [],
    meta: _91id_93pNkHQyrs60Meta,
    alias: _91id_93pNkHQyrs60Meta?.alias || [],
    redirect: _91id_93pNkHQyrs60Meta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/user/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93k0uCpSSVONMeta?.name ?? "verify-id",
    path: _91id_93k0uCpSSVONMeta?.path ?? "/verify/:id",
    children: [],
    meta: _91id_93k0uCpSSVONMeta,
    alias: _91id_93k0uCpSSVONMeta?.alias || [],
    redirect: _91id_93k0uCpSSVONMeta?.redirect || undefined,
    component: () => import("/opt/rusdram/packages/client/pages/verify/[id].vue").then(m => m.default || m)
  }
]