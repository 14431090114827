import { computed, watch } from "vue";
import { useUserStore } from "../stores";

export default async function useUserVerification() {
  const userStore = useUserStore();

  const user = computed(() => userStore.user);

  userStore.setUserVerify(true);

  watch([user], () => {
    if (user.value !== null) {
      if (user?.value.isVerified === true || user?.value.oauthTokens.length) {
        return userStore.setUserVerify(true);
      } else {
        return userStore.setUserVerify(false);
      }
    } else return;
  });

  return { user };
}
