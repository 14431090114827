<script setup lang="ts">
const { fetchUserData } = useAuth();

const { data: user } = await useAsyncData("fetchUserData", fetchUserData);

onBeforeMount(async () => {
  await useUserVerification();
});

onMounted(async () => {
  (function (src, cb) {
    var s = document.createElement("script");
    s.setAttribute("src", src);
    s.onload = cb;
    (document.head || document.body).appendChild(s);
  })(
    "https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js",
    function () {
      // @ts-expect-error it will run only on client side
      window.Blinkloader.optimize({
        pubkey: "UPLOADCARE_PUBLIC_KEY",
        fadeIn: true,
        lazyload: true,
        smartCompression: true,
        responsive: true,
        retina: true,
        webp: true,
      });
    }
  );
  (function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        ; (m[i].a = m[i].a || []).push(arguments)
      }
    // @ts-ignore
    m[i].l = 1 * new Date()
      ; (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a)
  })(
    window,
    document,
    'script',
    'https://mc.yandex.ru/metrika/tag.js',
    'ym'
  )

  // @ts-ignore
  ym(76682848, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: 'dataLayer'
  })

  //  Top.Mail.Ru counter
  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({id: "3497843", type: "pageView", start: (new Date()).getTime()});
  (function (d, w, id) {
    if (d.getElementById(id)) return;
    var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
    ts.src = "https://top-fwz1.mail.ru/js/code.js";
    var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
    if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
  })(document, window, "tmr-code");
});
</script>

<template>
  <div>
    <NuxtPage />
  </div>
  <div id="fb-root"></div>
</template>

<style>
body {
  background-color: #f7f7f7;
  overflow-x: hidden;
  max-width: 100vw;
}
</style>
