import { defineNuxtPlugin } from '#app'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default defineNuxtPlugin((nuxtApp) => {
    console.log(nuxtApp)
    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: "https://6e9b86abc3c624d6882f3a0b4619f50f@sentry.asar.studio/44",
        integrations: [
            new BrowserTracing(),
        ],
        maxBreadcrumbs: 1000,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        normalizeDepth: 100,
        autoSessionTracking: true
    });

    nuxtApp.vueApp.config.globalProperties.$sentry = Sentry;
    // nuxtApp.vueApp.config.errorHandler = (error, context) => {
    //     console.error(error)
    //     Sentry.addBreadcrumb({ data: context })
    //     Sentry.captureException(error)
    // }

    nuxtApp.hook('vue:error', (_args) => {
        // Sentry.captureException(..._args)
        console.error('vue:error', _args)
        // if (process.client) {
        //   console.log(..._args)
        // }
      })
      nuxtApp.hook('app:error', (_args) => {
        console.error('app:error', _args)
        // if (process.client) {
        //   console.log(..._args)
        // }
      })
      nuxtApp.vueApp.config.errorHandler = (..._args) => {
        console.error('global error handler', _args)
        // if (process.client) {
        //   console.log(..._args)
        // }
      }

})
