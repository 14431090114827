import { useCookie } from "#app";
import { useUserStore } from "../stores";
import { COOKIE_KEYS, LOCALSTORAGE_KEYS } from "../utils/constants";
import { User } from "../types";
import { useRouter } from "vue-router";
import { useAxios } from "./useAxios";

export function useAuth(
  params: {
    protect?: boolean;
    role?: string;
  } = { protect: false }
) {
  const { user, setUser, clearUser } = useUserStore();

  const authToken = useCookie(COOKIE_KEYS.authToken);

  const router = useRouter();

  //middleware
  function checkRouteProtection() {
    if (user) {
      return true;
    } else {
      router.push({ path: "auth" });
    }
  }

  async function fetchUserData() {
    const { $get } = useAxios();
    return new Promise((resolve, reject) => {
      $get<User>("/api/v3/user/data")
        .then((res) => {
          setUser(res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function login(userName: string, password: string) {
    const { $post, $get } = useAxios();
    console.log(userName, password);
    type Login = {
      accessToken: string;
      userName: string;
      password: string;
      refreshToken: string;
    };
    try {
      const { accessToken } = await $post<Login>("/api/v3/user/login", {
        userName,
        password,
      });

      authToken.value = `Bearer ${accessToken}`;

      localStorage.setItem(
        LOCALSTORAGE_KEYS.authToken,
        `Bearer ${accessToken}`
      );

      const userData = await $get("/api/v3/user/data", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      setUser(userData);

      return userData
    } catch (error) {
      // alert("проверьте введенность данных и попробуйте позже");
      console.error(error);
      console.error(error?.response?.body);
      throw error;
    }
  }

  async function facebookLogin(fbRes) {
    try {
      const { $post, $get } = useAxios();

      const { accessToken } = await $post("/api/v3/user/facebook-login", fbRes);

      authToken.value = `Bearer ${accessToken}`;

      localStorage.setItem(
        LOCALSTORAGE_KEYS.authToken,
        `Bearer ${accessToken}`
      );

      const userData = await $get("/api/v3/user/data", {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      setUser(userData);

      return userData
    } catch (error) {
      console.error(error);
      console.error(error?.response?.body);
      throw error;
    }
  }

  return { fetchUserData, checkRouteProtection, login, facebookLogin, user };
}
