import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import trackerAxios from '@openreplay/tracker-axios';
import trackerProfiler from '@openreplay/tracker-profiler';

import { useUserStore } from '~~/stores';

export default defineNuxtPlugin((nuxtApp) => {
  const userStore = useUserStore();
  const tracker = new Tracker({
    projectKey: "atdi6kZn6Q9EemIMLlNP",
    ingestPoint: "https://openreplay.asar.studio/ingest",
    __DISABLE_SECURE_MODE: true
  });

  tracker.use(trackerAssist());
  tracker.use(trackerAxios())
  tracker.use(trackerProfiler())

  tracker.start();

  if (userStore.user) {
    tracker.setUserID(userStore.user._id)
    tracker.setMetadata('firstName', userStore.user?.firstName);
    tracker.setMetadata('secondName', userStore.user?.secondName);
    tracker.setMetadata('email', userStore.user?.email);
    tracker.setMetadata('roles', userStore.user?.roles.join(', '));
  }

  window.tracker = tracker

  return {
    provide: {
      tracker: () => tracker
    }
  }
})

